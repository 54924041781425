import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import Button from 'antd/lib/button'
import Select from 'antd/lib/select'

import withFinalForm from '../../../../../../ui/core/with-final-form'

import '../Channel.css'
import { fieldRequired } from '../../../../../../helpers/form-validations/form-validations'
import {
  getChannelSenderIds,
  getSendingConfigState,
} from '../../../../../../redux/channel/channel-selectors'

import { TSenderId } from '../../../../../../ui/extension-contact/extension-contact-types'

const SelectComp = withFinalForm()(Select)
const { Option } = Select

type TChannelFormProps = {
  onSubmit: (values: TConfigChannelForm) => Promise<void>,
  onCancel: () => void
}

export type TConfigChannelForm = {
  senderId: string
}

function ChannelForm(props: TChannelFormProps) {
  const {
    onSubmit,
    onCancel,
  } = props

  const senderIds = useSelector(getChannelSenderIds) as TSenderId[]
  const isSendingConfig = useSelector(getSendingConfigState)

  return (
    <Form
      onSubmit={onSubmit}
    >
      {({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <label htmlFor="senderId" className="channels-label-senderid">
            <FormattedMessage id="channels.card.input.sender-id" />
            *
          </label>
          <p className="help-text-senderid">
            <FormattedMessage id="channels.card.input.help-text.sender-id" />
          </p>
          <Field
            name="senderId"
            className="sender-id"
            component={SelectComp}
            showSearch
            validate={fieldRequired()}
            optionFilterProp="children"
            disabled={isSendingConfig}
            placeholder="Select a sender ID"
            notFoundContent="There are no available Sender IDs to select"
          >
            {senderIds && senderIds.map((a) => (
              <Option key={a.number} value={a.number} data-test={a.number}>
                {a.displayLabel}
              </Option>
            ))}
          </Field>
          <div className="button-wrapper">
            <Button
              htmlType="button"
              onClick={onCancel}
              className="channel-btn-normal"
            >
              <FormattedMessage id="channels.configure-channel.cancel-configuration" />
            </Button>
            <Button
              htmlType="submit"
              disabled={isSendingConfig || invalid}
              className="channel-btn"
            >
              <FormattedMessage id="channels.configure-channel.save-configuration" />
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default ChannelForm
