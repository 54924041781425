import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import { FormattedMessage } from 'react-intl'
import Icon from '@ant-design/icons'

import { ReactComponent as MessageArrowUpRight } from '../../../../../../assets/img/icons/fa-message-arrow-up-right.svg'
import { ReactComponent as Messages } from '../../../../../../assets/img/icons/fa-messages.svg'
import { ReactComponent as Phone } from '../../../../../../assets/img/icons/phone.svg'

type TChannelIntroduction = {
  onNext: () => void
}

const { Title, Paragraph } = Typography

const supportLink = 'https://support.messagemedia.com/hc/en-us/articles/11873968904463-Sinch-SMS-MMS-HubSpot-Conversations-Inbox-Beta-Program'

function ChannelIntroduction(props: TChannelIntroduction) {
  const { onNext } = props

  return (
    <div>
      <Title level={4} className="channel-heading">
        <FormattedMessage id="channels.card.intro.list.heading" />
      </Title>
      <div className="channel-list">
        <div className="channel-list-item">
          <Icon className="channel-list-item-icon" component={MessageArrowUpRight} />
          <Title level={5} className="channel-list-item">
            <FormattedMessage id="channels.card.intro.list.item-1" />
          </Title>
          <Paragraph className="channel-list-description">
            <FormattedMessage id="channels.card.intro.list.item-1.description" />
          </Paragraph>
        </div>
        <div className="channel-list-item">
          <Icon className="channel-list-item-icon" component={Messages} />
          <Title level={5} className="channel-list-item">
            <FormattedMessage id="channels.card.intro.list.item-2" />
          </Title>
          <Paragraph className="channel-list-description">
            <FormattedMessage id="channels.card.intro.list.item-2.description" />
          </Paragraph>
        </div>
        <div className="channel-list-item">
          <Icon className="channel-list-item-icon" component={Phone} />
          <Title level={5} className="channel-list-item">
            <FormattedMessage id="channels.card.intro.list.item-3" />
          </Title>
          <Paragraph className="channel-list-description">
            <FormattedMessage id="channels.card.intro.list.item-3.description" />
          </Paragraph>
        </div>
      </div>
      <div className="button-wrapper">
        <Paragraph className="channels-support-article">
          <FormattedMessage
            id="channels.card.intro.support"
            values={{
              supportArticle: (
                <a className="support-article-link" target="_blank" rel="noreferrer noopener" href={supportLink}>
                  <FormattedMessage id="channels.card.intro.support.link" />
                </a>
              ),
            }}
          />
        </Paragraph>
        <Button
          type="primary"
          htmlType="button"
          onClick={onNext}
          className="channel-btn"
        >
          <FormattedMessage id="channels.card.intro.button" />
        </Button>
      </div>
    </div>
  )
}

export default ChannelIntroduction
