import cn from 'classnames'
import Typography from 'antd/lib/typography'
import { FormattedMessage } from 'react-intl'

type TChannelResultProps = {
  mode: string
  errorMessage?: string
}

const { Title, Paragraph } = Typography

function ChannelResult(props: TChannelResultProps) {
  const { mode, errorMessage } = props

  const LINK = (
    <a
      href="https://support.messagemedia.com/hc/en-us/requests/new?tf_10454796216207=brand_sinch_messagemedia"
      target="_blank"
      rel="noreferrer noopener"
    >
      this URL
    </a>
  )

  return (
    <div>
      <div className={cn({ 'channel-connect-success': mode === 'SUCCESS', 'channel-connect-fail': mode === 'FAIL' })}>
        {
          mode === 'SUCCESS'
          && (
            <div>
              <Title level={4} className="channel-connect-title">
                <FormattedMessage id="channels.connect.success" />
              </Title>
              <Paragraph className="channel-connect-text">
                <FormattedMessage id="channels.connect.success.description" />
              </Paragraph>
            </div>
          )
        }
        {
          mode === 'FAIL'
          && (
            <div>
              <Title level={4} className="channel-connect-title">
                <FormattedMessage id="channels.connect.failed" />
              </Title>
              <Paragraph className="channel-connect-text">
                <FormattedMessage id={errorMessage} defaultMessage={errorMessage} values={{ link: LINK }} />
              </Paragraph>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ChannelResult
