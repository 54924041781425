import { useState, useEffect } from 'react'
import Layout from 'antd/lib/layout'
import Card from 'antd/lib/card'
import Spin from 'antd/lib/spin'
import { useDispatch, useSelector } from 'react-redux'
import _get from 'lodash/get'

import ChannelForm from './channel-form'
import { TConfigChannelForm } from './channel-form/channel-form'

import useQuery from '../../../../../hocs/use-query'
import { intl } from '../../../../../ui/i18n'
import {
  getChannelReadyState,
} from '../../../../../redux/channel/channel-selectors'
import { getSenderIds, sendConfiguration } from '../../../../../redux/channel/channel-actions'

import './Channel.css'
import ChannelIntroduction from './channel-introduction'
import ChannelResult from './channel-result'

const { Content } = Layout

function Channel() {
  const [step, setStep] = useState('INTRODUCTION')
  const [connectErrorMessage, setConnectErrorMessage] = useState<string|undefined>(undefined)
  const [exitStatus, setExitStatus] = useState('SUCCESS')
  const query = useQuery()
  const dispatch = useDispatch()

  const portalId = query.get('portalId') || 'portalid'
  const channelId = query.get('channelId') || 'channelid'
  const accountToken = query.get('accountToken') || 'accounttoken'
  const inboxId = query.get('inboxId') || 'inboxid'

  const ready = useSelector(getChannelReadyState)

  useEffect(() => {
    if (!ready) {
      const fetchSenderIds = async () => {
        dispatch(getSenderIds({ portalId }))
      }
      fetchSenderIds()
    }
  }, []) // eslint-disable-line

  const onNext = () => {
    setStep('FORM')
  }

  const onBack = (newStep: string) => {
    setStep(newStep)
  }

  const onSubmit = async (values: TConfigChannelForm) => {
    const body = {
      ...values,
      portalId,
      channelId,
      accountToken,
      inboxId,
    }

    try {
      await dispatch(sendConfiguration(body))
      setExitStatus('SUCCESS')
      setTimeout(() => {
        window.close()
      }, 3000)
    } catch (error) {
      setConnectErrorMessage(_get(error, 'message', 'channels.connect.failed.description'))
      setExitStatus('FAIL')
    } finally {
      setStep('EXIT')
    }
  }

  const renderContent = () => {
    if (!ready) {
      return (
        <div className="spinner-layout ">
          <Spin size="large" className="spin" />
        </div>
      )
    }

    return (
      <Card
        className="configure-channel-card"
        title={intl.formatMessage({ id: 'channels.configure-channel.title' })}
        data-test="configure-channel-card"
      >
        <img className="channel-logo" src="/logos/sinch-hubspot.png" alt="sinch-hubspot-logo" />
        {
          step === 'INTRODUCTION'
          && (
            <ChannelIntroduction
              onNext={onNext}
            />
          )
        }
        {
          step === 'FORM'
          && (
            <ChannelForm
              onSubmit={onSubmit}
              onCancel={() => onBack('INTRODUCTION')}
            />
          )
        }
        {
          step === 'EXIT'
          && (
            <ChannelResult
              mode={exitStatus}
              errorMessage={connectErrorMessage}
            />
          )
        }
      </Card>
    )
  }

  return (
    <Layout>
      <Content>
        {renderContent()}
      </Content>
    </Layout>
  )
}

export default Channel
